/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef,useState } from 'react';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import * as RegistrationConstants from './RegistrationConstants';
import ErrorMessages from '../../../SharedModule/MessageHandlers/ErrorMessages';
import SuccessMessage from '../../../SharedModule/MessageHandlers/SuccessMessage';
import Spinner from '../../../SharedModule/Spinner/Spinner';
import moment from 'moment';
import Radio from "@material-ui/core/Radio";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TableComponent from '../../../SharedModule/Table/Table';
import { appDetailsAction} from '../Store/Actions/RegistrationActions';
import {memberGetAttributesActions,memberConsentSaveActions} from '../Store/Actions/RegistrationActions';
import * as AppConstants from '../../../SharedModule/Utils/AppConstants';
import { TrainRounded } from '@material-ui/icons';
import axios from 'axios';
import * as serviceEndPoint from '../../../SharedModule/Service/service';
import { useHistory } from 'react-router-dom';

import { validateMember ,getMemberProfileDetails} from '../../Login/Components/ValidateMember';
import PopupModal from '../../Login/Components/PopupModal';
import { CONFIDENTIALITY_STATUS } from './RegistrationConstants';






export default function MemberConsent(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState(null);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

   
    //const registrationRef = useRef(null);
    const disabled=true;
   // const userconsentInfo
    const [userconsentInfo, setUserInfo] = useState({
        consentselected: [],
        response: [],
      });
    const [open, setOpen] = React.useState(false);
    const [decision, setDecision] = React.useState(null);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };  
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [memberConsentInfo, setMemberConsentData] = React.useState({
        appName: '',
        appOrganization:'',
        privacyPolicyURL:'',
        privacyQuestionnaireURL:'',
        consentId:'',
        termsOfURL:''

    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const history = useHistory();
    const [userName, setUserName] = useState('');
    const [isConsentUpdated, setIsConsentUpdated] = useState(false);
    const [userInfo, UserInfo] = useState(null);

    const hiddenButtonRef = useRef(null);
    const consentRef = React.useRef();
    const memberGetAttributesdata=(data)=>dispatch(memberGetAttributesActions(data));
    const memberConsentSaveDispatch = (data) => dispatch(memberConsentSaveActions(data));
    
    // const Token = (data) => dispatch(TokenActions(data));

    const query = new URLSearchParams(window.location.search);
    const tokenId = query.get('consent_request');
    //memberGetAttributesdata(tokenId);
     const apiSuccessAction = useSelector(state => state.registrationState.apiResponse);
    const memberConsentDetails = useSelector(state=>state.registrationState.memberConsentDetails);
    const memberConsentSaveResponse = useSelector(state=>state.registrationState.memberConsentSaveResponse);
    let errorMessagesArray = [];


    const storedClientId = sessionStorage.getItem('clientId');
    const redirect_uri = sessionStorage.getItem('redirect_uri');
    const Scope = sessionStorage.getItem('scope');
    const state = sessionStorage.getItem('state');
    const authorization = sessionStorage.getItem('authorization');
    const request = sessionStorage.getItem('req');


    const decodeJWT = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => 
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(jsonPayload);
    };


    const DecodeUserInfo = () => {
        const token = sessionStorage.getItem("token");
         return token ? decodeJWT(token) : null;
    };

    const navigateModal = () => {
        setShowModal(false);
        sessionStorage.setItem('fromThirdParty', 'true');
        history.push('/registration');
        // history.push('/home');
    };



    useEffect(() => {
        const userInfo = DecodeUserInfo();
        setSpinnerLoader(true);

        validateMember(userInfo)
            .then(validationResponse => {
                if (validationResponse && validationResponse.status === "MEMBER_FOUND") {
                    
                    getMemberProfileDetails(userInfo.oid)
                        .then(profileDetailsResponse => {
                            if (profileDetailsResponse && profileDetailsResponse.status === "Active") {
                                let obj = {
                                   
                                     userId: userInfo.oid,
                                    clientId:storedClientId,
                                    
                        
                                    
                                };
                                memberGetAttributesdata(obj);
                               
                            } else {
                               setErrorMessages([CONFIDENTIALITY_STATUS]);
                                

                            }
                        })
                        .catch(error => {
                           
                            console.error('Error fetching member profile details:', error);
                            setErrorMessages([error.response?.data?.message || "An error occurred while fetching member profile details."]);
                        });
                } else if (validationResponse && validationResponse.status !== "MEMBER_FOUND") {
                    setModalMessage("Member not registered with us. Please Register Member First.");
                    setShowModal(true);
                    
                    // alert("Member not registered with us. Please Register Member First.");
                    // history.push('/registration');
                } else {
                    
                    setErrorMessages(['Error occurred during member validation.']);
                }
            })
            .catch(error => {
               
                console.error('Error validating member:', error);
                setErrorMessages([error.response?.data?.message || "An unexpected error occurred."]);

            })
            .finally(() => {
                setSpinnerLoader(false);
            });
    }, [dispatch]);
    
    
    

    useEffect(() => {
        if (memberConsentDetails) {
          
            if (memberConsentDetails?.success){
                errorMessagesArray = [];
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentDetails, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setSpinnerLoader(false);
                    setErrorMessages(errorMessagesArray);
                } else {
                setSpinnerLoader(false);
                setMemberConsentData(memberConsentDetails.data);
                }
            }
            else{
                setSpinnerLoader(false);
                errorMessagesArray = [];
            
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentDetails, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setErrorMessages(errorMessagesArray);
                }
                else{
                    if (memberConsentDetails.message){
                        setErrorMessages([memberConsentDetails.message]);
                    }
                    else{
                    setErrorMessages(['Unable to process your request . Please try later.']);
                    }
                }
            }
        }
    }, [memberConsentDetails]);

    useEffect(() => {
            if (memberConsentSaveResponse) {
                sessionStorage.setItem('memberId', memberId);
                const memberId = sessionStorage.getItem('memberId');
            if (memberConsentSaveResponse?.success){
                hiddenButtonRef.current.click(); 
            }
            else{
                setSpinnerLoader(false);
                errorMessagesArray = [];
            
                errorMessagesArray = AppConstants.handleAPIErrors(memberConsentSaveResponse, errorMessagesArray, '');
                if (errorMessagesArray && errorMessagesArray.length > 0) {
                    setErrorMessages(errorMessagesArray);
                }
                else {
                    if (memberConsentSaveResponse.message){
                        setErrorMessages([memberConsentSaveResponse.message]);
                    }
                    else{
                        setErrorMessages(['Unable to process your request  . Please try later.']);
                    }
                }
            }
        }
    }, [memberConsentSaveResponse]);

    const submitForm = (e) => {
        e.preventDefault()
      
    }
    const approveAccess = (e) => {
        e.preventDefault()
      
        const userInfo = DecodeUserInfo();

        setDialogOpen(false);
        handleClose();
        selectedScopes();
        const mappedStoreResponse = storeResponse.map(scope => consentKeyMap[scope] || scope);

    
    const Scope = (sessionStorage.getItem('scope') || '').split(' ');
    const intersectionScopes =mappedStoreResponse.filter(scope => Scope.includes(scope));
    const DefaultScopes = ['openid', 'profile'];
    DefaultScopes.forEach(scope => {
        if (Scope.includes(scope) && !intersectionScopes.includes(scope)) {
            intersectionScopes.push(scope);
        }
    });
     sessionStorage.setItem('intersectionScopes', JSON.stringify(intersectionScopes));
        let obj={
        
              clientId:storedClientId,
              userId: userInfo.oid,
             "consentId": memberConsentInfo.consentId,
             "consentDecision": true,
             "consentValues":userconsentInfo.response,
             "consentValues":storeResponse
             
          }
        setDecision(true);
        setSpinnerLoader(true);
        memberConsentSaveDispatch(obj); 
          setIsConsentUpdated(true);    
    };


//newwwww

useEffect(() => {
    if (isConsentUpdated){
         const userInfo = DecodeUserInfo();
         const redirect_uri = sessionStorage.getItem('redirect_uri');
        const userId=userInfo.oid;
        const clientId = storedClientId;
        const clientredirecturl =redirect_uri;
        const storedScopes = sessionStorage.getItem('intersectionScopes');
        const Scope = JSON.parse(storedScopes).join(' ');
        const concatenatedString  = `${Scope},${clientId},${userId},${clientredirecturl}`;
        const code = btoa(concatenatedString);
        const redirect = `${redirect_uri}?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}&client_id=${encodeURIComponent(clientId)}`;
        window.location.href = redirect;
    
    }
 }, [isConsentUpdated]);




    const denyAccess = () => {
        const userInfo = DecodeUserInfo();
        setDialogOpen(false);
        handleClose();
        let obj={
             clientId:storedClientId,
             userId: userInfo.oid,
             "consentId": memberConsentInfo.consentId,
             "consentDecision":false,
             "consentValues":[]
        }
        setDecision(false);
        setSpinnerLoader(true);
        memberConsentSaveDispatch(obj);
          window.location.href = redirect_uri;
        // redirectToHomePage();    
        
    };

    const redirectToHomePage = () =>{
        props.history.push({
            pathname: "/",
          });
    }

    const showApproveDialog = () => {
        setDialogOpen(true);
        setDialogType('submit')
        return true;
        
    };

    const defaultvalues = {event1:true, event2:true, event3:true,event4:true,event5:true};
    const trueValues =    {event1:"Insurance Coverage Information",event2:"Explanation Of Benefits",
                           event3:"My Personal Information", event4:"Provider Information",event5:"Clinical Data",
                           event6:"Allergies and Intolerances",event7:"Assessment and Plan of Treatment",
                           event8:"Care Team Member(s)",event9:"Clinical Notes",
                           event10:"Clinical Tests - non-imaging",event11:"Encounter Information",event12:"Goals",
                           event13:"Health Concerns",event14:"Immunizations", event15:"Laboratory",
                           event16:"Medications",event17:"Problems",event18:"Procedures",
                           event19:"Provenance",event20:"Smoking Status",
                           event21:"Unique Device Identifier(s) for a Patient's Implantable Device(s)",event22:"Vital Signs"
                            }

   const consentKeyMap = {
                       "Insurance Coverage Information": "CoverageInfo",
                        "Explanation Of Benefits": "EOB",
                        "My Personal Information": "PatientInfo",
                        "Provider Information": "ProviderInfo",
                        "Clinical Data": "ClinicalData",
                        "Allergies and Intolerances": "Allergy",
                        "Assessment and Plan of Treatment": "CarePlan",
                        "Care Team Member(s)": "CareTeam",
                        "Clinical Notes": "ClinicalNotes",
                        "Clinical Tests - non-imaging": "ClinicalTests",
                        "Encounter Information": "Encounter",
                        "Goals": "Goals",
                        "Health Concerns": "HealthConcerns",
                        "Immunizations": "Immunizations",
                        "Laboratory": "Laboratory",
                        "Medications": "Medications",
                        "Problems": "Problems",
                        "Procedures": "Procedures",
                        "Provenance": "Provenance",
                        "Smoking Status": "Smoking",
                        "Unique Device Identifier(s) for a Patient's Implantable Device(s)": "UniqueDevice",
                        "Vital Signs": "Vitals",
                        "openid": "openid",
                        "profile": "profile"
                            }

    const defaultChild =  {event6:true,event7:true,event8:true,event9:true,event10:true,event11:true,
                           event12:true,event13:true,event14:true,event15:true,event16:true,event17:true,
                           event18:true,event19:true,event20:true,event21:true,event22:true}       
                   
    const [checked, setChecked] = React.useState(defaultvalues);
    const [checkChild,setCheckChild]=React.useState(defaultChild);
    let parent=  Object.keys(checked)
    let child= Object.keys(checkChild) 
    const handleChange = (event) => {
    let val = event.target.value;
    let dumCheck = checked;
    let evenCheck = event.target.checked;
    if(parent.includes(val)){
    setChecked(prevState => ({
        ...prevState,
        [val]: evenCheck
    }));}
    else{
        setCheckChild(prevState => ({
            ...prevState,
            [val]: evenCheck
        }));
    }
  };
    let storeResponse=[]
    let scope=[]
    useEffect(()=>{
    if(checked.event5){
    setCheckChild({event6:true,event7:true,event8:true,event9:true,event10:true,event11:true,
                   event12:true,event13:true,event14:true,event15:true,event16:true,event17:true,
                   event18:true,event19:true,event20:true,event21:true,event22:true});
    }
    else{
    setCheckChild({event6:false,event7:false,event8:false,event9:false,event10:false,event11:false,
                   event12:false,event13:false,event14:false,event15:false,event16:false,event17:false,
                   event18:false,event19:false,event20:false,event21:false,event22:false});
    }
    },[checked])
    const selectedScopes=()=>{
    scope=parent.concat(child)
    scope=scope.filter((item)=>((checked[item] && checked[item] !== false) || ( checkChild[item] && checkChild[item] !== false)))
    scope.map((item)=>{
    storeResponse.push(trueValues[item])
   })
    return storeResponse
   }
    const [showExtra,setShowExtra]= React.useState(false);
    // const handleClick =(event)=>{
    //     setShowExtra(true);
    // };
    const handleClick = () => {
        setShowExtra(prevShowExtra => !prevShowExtra);
    }

    const children = (
        <div className="form-wrapper col-sm-12 pl-0 pr-3 my-0">                                                             
            <div className="col-sm-7 col-md-3 m-0">
                <div className="mui-custom-form mb-3">
                   <FormGroup>  
                       <FormControlLabel id="Allergies and Intolerances" label="Allergies and Intolerances"
                                         control={<Checkbox  id="Allergies and Intolerances" name="Allergies and Intolerances" 
                                         value="event6" color="primary" checked={checkChild && checkChild.event6? true : false} onChange={handleChange} />}/>
                       <FormControlLabel id="Clinical Notes" label="Clinical Notes"
                                         control={<Checkbox  id="Clinical Notes" name="Clinical Notes" 
                                         value="event9" color="primary" checked={checkChild && checkChild.event9? true : false} onChange={handleChange} />}/> 
                       <FormControlLabel id="Goals" label="Goals"
                                         control={<Checkbox id="Goals" name="Goals"
                                         value="event12" color="primary" checked={checkChild && checkChild.event12? true : false} 
                                         onChange={handleChange} />}/> 
                       <FormControlLabel id="Laboratory" label="Laboratory"
                                         control={<Checkbox  id="Laboratory" name="Laboratory" 
                                         value="event15" color="primary" checked={checkChild && checkChild.event15? true : false} onChange={handleChange} />}/>
                       <FormControlLabel id="Procedures" label="Procedures"
                                         control={<Checkbox  id="Procedures" name="Procedures" 
                                         value="event18" color="primary"checked={checkChild && checkChild.event18? true : false} onChange={handleChange} />}/>
                       <FormControlLabel id="Unique Device Identifier(s) for a Patient's Implantable Device(s)"
                                         label="Unique Device Identifier(s) for a Patient's Implantable Device(s)"
                                         control={<Checkbox  id="Unique Device Identifier(s) for a Patient's Implantable Device(s)" 
                                         name="Unique Device Identifier(s) for a Patient's Implantable Device(s)" 
                                         value="event21" color="primary" checked={checkChild && checkChild.event21? true : false} onChange={handleChange} />} />
                    </FormGroup>
                </div>
            </div>
              <div className="col-sm-7 col-md-3 m-0">
                <div className="mui-custom-form mb-3">         
                  <FormGroup> 
                    <FormControlLabel id="Assessment and Plan of Treatment" label="Assessment and Plan of Treatment"
                                      control={<Checkbox  id="Assessment and Plan of Treatment" name="Assessment and Plan of Treatment" 
                                      value="event7" color="primary" checked={checkChild && checkChild.event7? true : false} onChange={handleChange} />}/>
                    <FormControlLabel id="Clinical Tests - non-imaging"  label="Clinical Tests - non-imaging"
                                      control={<Checkbox  id="Clinical Tests - non-imaging" name="Clinical Tests - non-imaging" 
                                      value="event10" color="primary" checked={checkChild && checkChild.event10? true : false} onChange={handleChange} />} />              
                    <FormControlLabel id="Health Concerns" label="Health Concerns"
                                      control={<Checkbox id="Health Concerns" name="Health Concerns" 
                                      value="event13" color="primary" checked={checkChild && checkChild.event13? true : false}  onChange={handleChange} />} /> 
                    <FormControlLabel id="Medications"  label="Medications"
                                      control={<Checkbox  id="Medications" name="Medications" 
                                      value="event16" color="primary" checked={checkChild && checkChild.event16? true : false} onChange={handleChange} />}  />
                    <FormControlLabel id="Provenance" label="Provenance"
                                      control={<Checkbox  id="Provenance" name="Provenance" 
                                      value="event19" color="primary" checked={checkChild && checkChild.event19? true : false} onChange={handleChange} />}    />
                 </FormGroup>
                </div>
            </div>
        <div className="col-sm-7 col-md-3 m-0">
            <div className="mui-custom-form mb-3">
                <FormGroup>
                    <FormControlLabel id="Care Team Member(s)"  label="Care Team Member(s)"
                                      control={<Checkbox  id="Care Team Member(s)" name="Care Team Member(s)" 
                                      value="event8" color="primary" checked={checkChild && checkChild.event8? true : false} onChange={handleChange} />}    />
                    <FormControlLabel id="Encounter Information" label="Encounter Information"
                                      control={<Checkbox  id="Encounter Information" name="Encounter Information" 
                                      value="event11" color="primary" checked={checkChild && checkChild.event11? true : false} onChange={handleChange} />} />
                    <FormControlLabel id="Immunizations" label="Immunizations"
                                      control={<Checkbox  id="Immunizations" name="Immunizations" 
                                      value="event14" color="primary" checked={checkChild && checkChild.event14? true : false} onChange={handleChange} />}  />
                    <FormControlLabel id="Problems"   label="Problems"
                                      control={<Checkbox  id="Problems" name="Problems" 
                                      value="event17" color="primary" checked={checkChild && checkChild.event17? true : false} onChange={handleChange} />} />
                    <FormControlLabel id="Smoking Status"  label="Smoking Status"
                                      control={<Checkbox  id="Smoking Status" name="Smoking Status" 
                                      value="event20" color="primary" checked={checkChild && checkChild.event20? true : false} onChange={handleChange} />}  />         
                    <FormControlLabel id="Vital Signs"  label="Vital Signs" control={<Checkbox  id="Vital Signs" name="Vital Signs" 
                                      value="event22" color="primary" checked={checkChild && checkChild.event22? true : false} onChange={handleChange} />}   />
               </FormGroup>
            </div>
        </div>
    </div>
        );
 

    return (
        <div className="block-elm" >
            
            {spinnerLoader ? <Spinner /> : null}
            {errorMessages && errorMessages.length > 0 ?
                <div className="form-wrapper col-sm-12 px-0 mt-2">
                <ErrorMessages errorMessages={errorMessages} />
                </div> : null}


            {memberConsentSaveResponse && memberConsentSaveResponse.data?
            <form  action={memberConsentSaveResponse.data.consentApprovalRedirectUri} method="post">
                <input ref={consentRef} type="hidden" name="consent_response" value={memberConsentSaveResponse.data.signedJwt}/>
                <input onClick={submitForm} type="submit" style={{ display: 'none' }} ref={hiddenButtonRef} />
            </form>:null}

            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="custom-alert-box">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to allow access?
   
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    
                    {dialogType === 'submit' && (
                        <Button onClick= { approveAccess } color="primary" className="btn btn-success">
                            Ok
                        </Button>
                    )
                    }


                    <Button onClick={() => { setDialogOpen(false); }} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <!-- main content start --> */}
            {memberConsentDetails != null  && memberConsentDetails.data?
            <div className="tabs-container container">
                <div className="contactUs-container pl-0 row">
                    <div className="contactUs-subContainer"> 
                        <div className="terms-of-use-head p-4">
                            <b className="p-2 dib"> MEMBER CONSENT ACCESS </b>
                        </div>
                                    {/* border block start */}
                                    
                                    {/* border block end */}
                                    {/* border block start */}
                                    
                        <div className="container-body p-4 mt-2">
                            <section className="block-elm px-2 mb-3"><p><b>Allow Access to Your Account</b></p>
                            </section>
                            <section className="block-elm px-2 mb-3"><p>{memberConsentInfo.appName} wants to share your health data with them.
                            </p>
                            <p>What you need to know about {memberConsentInfo.appName}?
                            </p>
                                    <p>{memberConsentInfo.appName} is not provided by {process.env.REACT_APP_CLIENT_NAME}; it is provided by {memberConsentInfo.appOrganization}. {memberConsentInfo.appOrganization} is not covered by the Health Insurance Portability and Accountability Act, or HIPAA.  Please review {memberConsentInfo.appName}'s Privacy Policy at <a href={memberConsentInfo.privacyPolicyURL} target="_blank">{memberConsentInfo.privacyPolicyURL}</a> and Terms of Service at <a href={memberConsentInfo.termsOfURL} target="_blank">{memberConsentInfo.termsOfURL}</a> before allowing {memberConsentInfo.appName} to access to your account.  The privacy policy should explain clearly the answers to the following questions:</p>
                            </section>
                            <div className="block-elm px-2 mb-3">
                                <ul className="numberList">
                                    <li>Who is offering the app?</li>
                                    <li>How is this app funded?</li>
                                    <li>Where does this app save your data?</li>
                                    <li>Who has access to your data when you provide it to this app?  Does this app use third-party persistent cookies, also known as tracking cookies?</li>
                                    <li>Does the app developer allow you to obtain a record of the data collected about you?</li>
                                    <li>Does this app allow you to delete the data stored about you?</li>
                                    <li>How does the app developer use your data?</li>
                                </ul>
                            </div>

                            <section className="block-elm px-2 mb-3">
                            <p>This app will have access to your information for a period of 90 days, after which you will need to sign in again.</p>
                            <p>{process.env.REACT_APP_CLIENT_NAME} may revoke access to this app based on fraud, security, or other concerns that could harm our participants.</p>
                            <p>Ability to access data via an app may be restricted due to {process.env.REACT_APP_CLIENT_NAME} participant age and/or eligibility in accordance with state and federal policies and regulations.</p>
                            <p> <b>Allow or Deny Access</b>
                            </p>
                            <p>{memberConsentInfo.appName} wants your permission to access the following information:</p>

                            <p>Please unselect the data you don't want to share</p>

                             
                            <div className="form-wrapper col-sm-12 pl-0 pr-3 my-0">
                              <div className="col-sm-6 col-md-3 m-0">
                                <div className="mui-custom-form mb-3">
                                <FormGroup>
                                <FormControlLabel id="insurancecoverage-checkbox" 
                                                  control={<Checkbox checked={checked && checked.event1? true : false} id="insurancecoverage" name="insurancecoverage" 
                                                  value='event1' inputProps={{ "aria-label": "controlled" }} 
                                                  color="primary" onChange= {handleChange} /> } label='Insurance Coverage Information' />
                                <FormControlLabel id="mypersonalInfo-checkbox" 
                                                  control={<Checkbox checked={checked && checked.event3? true : false} id="mypersonalinfo" name="My Personal Information"  
                                                  value="event3" inputProps={{ "aria-label": "controlled" }} color="primary" 
                                                  onChange= {handleChange} /> } label="My Personal Information (Date of Birth, Member ID, etc.)" disabled checked /> 
                                <FormControlLabel id="Clinical-dataBox" 
                                                  control={<Checkbox checked={checked && checked.event5? true : false} 
                                                  id="clinicaldata" name="Clinical Data" value="event5" 
                                                  color="primary" onChange= {handleChange} /> } label="Clinical Data"/> 
                                </FormGroup>                             
                                </div>                   
                              </div>
                                        
                    <div className="col-sm-6 col-md-3 m-0">
                        <div className="mui-custom-form mb-3">
                            <FormGroup>
                                <FormControlLabel id="eob-checkbox" control={<Checkbox checked={checked && checked.event2? true : false} 
                                                  id="eob" name="eob" value="event2" inputProps={{ "aria-label": "controlled" }} color="primary" 
                                                  onChange= {handleChange} />} label="Explanation Of Benefits" />
                                <FormControlLabel id="providerInfo-checkbox" 
                                                  control={<Checkbox checked={checked && checked.event4? true : false} id="providerinfo" name="Provider Information" 
                                                  value="event4" color="primary" onChange= {handleChange} />} label="Provider Information" />  
                            </FormGroup>
                                </div>
                            </div>
                        </div> 
                        <strong>{/* [<span onClick={handleClick}>+</span>] If you would like to share a specific type of clinical data, please click on expand [+] and select the individual resources. */}
                            [<span onClick={handleClick}>{showExtra ? '-' : '+'}</span>] If you would like to share a specific type of clinical data, please click on [<span onClick={handleClick}>{showExtra ? '-' : '+'}</span>] and select the individual resources. 
                            Not all resources are available. This application holds only data provided by the State of South Dakota. </strong>
                                {showExtra?<>{children}</>:null}      

                            </section>
                            <div className="block-elm px-2 mb-3">
                                <ul className="numberList">
                                    <li>Personal Information (Full Name, Date of Birth, Address)</li>
                                    <li>Claims and Clinical Data (Doctor visit, Treatment, Medication, Cost details, Lab Results, Substance Use Disorder Treatment, Procedures, Conditions etc.) </li>

                                </ul>
                            </div>
                            <section className="block-elm px-2 mb-3">
                                    <p>{memberConsentInfo.appName} is not provided by {process.env.REACT_APP_CLIENT_NAME}; it is provided by {memberConsentInfo.appOrganization}. {memberConsentInfo.appOrganization} is not covered by the Health Insurance Portability and Accountability Act (HIPAA) or applicable state laws.  By allowing {memberConsentInfo.appOrganization} access to your personally identifiable information, you acknowledge that {process.env.REACT_APP_CLIENT_NAME} is not responsible for any disclosure of your data by {memberConsentInfo.appOrganization}.  Once your data leaves {process.env.REACT_APP_CLIENT_NAME}, we can no longer protect it.</p>
                            <p>The Privacy Policy Questionnaire responses provided by {memberConsentInfo.appName} may be found <a href={memberConsentInfo.privacyQuestionnaireURL} target="_blank">here</a>.</p>
                            <p >Alcohol and substance use disorder treatment records are specifically protected by federal regulations (42 CFR Part 2) and by agreeing to the release to the {memberConsentInfo.appName} you are allowing the release of any alcohol and/or substance use information or records (if any) that you may have.</p>
                            
                            <p>Prohibitions of Re-Disclosure: Federal regulations (42 CFR Part 2) prohibit the recipient of substance use disorder treatment records from making further disclosure of those records without the specific written authorization of the person to whom those records pertain, or as otherwise specified by such regulation.</p>
                           
                            <p>Do you approve or deny the release of your data, including alcohol and substance use disorder treatment information:</p>
                            
                            </section>
                        </div>
                        <div className="float-center th-btnGroup py-3">
                            <Button variant="outlined" color="primary" className='btn btn-cancel' 
                            onClick={denyAccess}>
                                Deny Access </Button>
                            <Button variant="outlined" color="primary" className='btn btn-ic btn-next' id="submitButton"
                                onClick={showApproveDialog}>
                                Allow access </Button>
                            
                        </div>
                                    
                        {/* border block end */}
                        {/* border block start */}
                        
                        {/* border block end */}
                    </div>

                </div>
                        
            </div>:null}

            <PopupModal
            showModal={showModal}
            message={modalMessage}
            onConfirm={() => {
                navigateModal();
                // history.push('/registration');
            }}
            buttonText="Ok"
        />
        </div>
    )
}
